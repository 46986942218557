.background {
  background-color: var(--primaryBG);
  height: 100vh;
  width: 100vw;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 42rem;
  height: auto;
  flex-direction: column;
  padding: 4.8rem;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: var(--shadowDark);
  z-index: 10;
}

.imgContainer {
  margin-bottom: 5rem;
}

.img {
  width: 100%;
}

.error {
  margin-bottom: 2.4rem;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 7.2rem;
}

.button {
  align-self: center;
}
