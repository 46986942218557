.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay);
  backdrop-filter: blur(0.3rem);
  z-index: 5;
}

.hidden {
  display: none;
}
