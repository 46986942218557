.container {
  display: block;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.6rem;
  user-select: none;
  color: var(--primaryDark);
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.8rem;
  width: 1.8rem;
  background-color: #eee;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: var(--primaryDark);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 0.6rem;
  left: 0.6rem;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background: white;
}
