/* Start CheckBox */

.checkWrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.checkWrapper input[type='checkbox'] {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.6rem;
  margin-right: 0.1rem;
  outline: none;
  cursor: pointer;
}

input {
  border: 0.2rem solid var(--greyLight);
}

input.checked {
  border: none;
}

.checked {
  position: relative;
  background-color: var(--primaryDark);
}

.checked::before {
  content: '\2714';
  font-size: 1.6rem;
  color: var(--white);
  animation: scaleA 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) alternate;
  transition: 0.3s;
}

.text {
  color: var(--black);
  font-size: 1.4rem;
  font-weight: 500;
  user-select: none;
}

.active {
  color: var(--primaryDark);
}

@keyframes scaleA {
  0% {
    transform: scale(10%);
  }
}
