.button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.button:hover {
  transform: scale(90%);
}

.check {
  color: #005a2d;
}

.x {
  color: #ca024f;
}
