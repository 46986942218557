.content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
}

.topBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.editButtons {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.changePassword {
  align-self: flex-end;
}

.roles {
  padding-bottom: 2rem;
}

.roleTitle {
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--primaryDark);
}
