.box {
  background-color: var(--primaryBG);
  display: flex;
  height: 100vh;
  width: 100vw;
}

.content {
  margin: 6rem;
}

.contentOpen {
  composes: content;
  width: calc(100% - 38rem);
  transition: width 400ms;
  transition-delay: 200ms;
}

.contentClosed {
  composes: content;
  width: 100%;
}

.sideBarClosed {
  width: 7.8rem;
  transition: 300ms ease-in-out;
  transition-delay: 0.1s;
}

.sideBarOpen {
  width: 26rem;
  transition: 200ms ease-in;
}
