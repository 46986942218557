.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 4.8rem;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: var(--shadowDark);
  z-index: 10;
}

.close {
  color: var(--primaryDark);
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
}

.close:hover {
  opacity: 0.8;
}

.title {
  color: var(--primaryDark);
  padding-bottom: 4rem;
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  user-select: none;
}

.sm {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.md {
  display: grid;
  grid-template-columns: 30rem 30rem;
  grid-template-rows: 5rem 5rem;
  gap: 4rem;
}

.lg {
  display: grid;
  grid-template-columns: 30rem 30rem 30rem;
  grid-template-rows: 5rem 5rem 5rem;
  gap: 4rem;
}

.buttons {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  padding-top: 6rem;
}

.viewOnly {
  pointer-events: none;
}
