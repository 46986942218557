.pagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.perPageBox {
  display: flex;
  gap: 0.5rem;
}

.pagesBox {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.buttonsBox {
  display: flex;
  gap: 0.3rem;
}

.text {
  font-size: 1.4rem;
  color: var(--primaryDark);
  font-weight: 500;
  user-select: none;
}
