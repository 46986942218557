.content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
}

.topBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.searchBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  color: var(--primaryDark);
}

.errorBox {
  grid-column: 1/4;
  margin-top: 1.2rem;
}

.icon {
  cursor: pointer;
}

.toggles {
  display: flex;
  justify-content: space-between;
}
