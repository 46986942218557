.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  height: 100%;
}

.searchBox {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.icons {
  display: flex;
  gap: 1.5rem;
}

.icon {
  color: var(--primaryDark);
  opacity: 0.3;
  cursor: pointer;
}

.selected {
  color: var(--primaryDark);
  opacity: 1;
}

.export {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.charts {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4rem;
  height: 100%;
}

.attendanceChart {
  grid-column: 1/3;
  height: 100%;
  border-radius: 1rem;
  box-shadow: var(--shadowDark);
  background-color: var(--white);
}

.attendanceChartUser {
  composes: attendanceChart;
  grid-row: 1/3;
}

.productivityChart {
  grid-row: 2/3;
  grid-column: 2/3;
  border-radius: 1rem;
  box-shadow: var(--shadowDark);
  background-color: var(--white);
}

.activityChart {
  grid-row: 2/3;
  grid-column: 1/2;
  width: 98%; /* Fixes responsiveness when opening menu */
  border-radius: 1rem;
  box-shadow: var(--shadowDark);
  background-color: var(--white);
}
