/* Start links */

.link {
  display: flex;
  align-items: center;
  height: 6rem;
  width: 100%;
  margin: 0.5rem 0;
  border: none;
  font-weight: 700;
  font-size: 1.6rem;
  cursor: pointer;
  letter-spacing: 0.15rem;
  user-select: none;
}

.link,
.linkClose {
  color: var(--white);
}

.linkActive,
.link:hover,
.linkClose:hover {
  background-color: var(--primaryBG);
  color: var(--primaryDark);
}

.linkActive {
  display: flex;
  align-items: center;
  height: 6rem;
  width: 100%;
  margin: 0.5rem 0;
  padding-left: 3rem;
}

.linkInactive {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3rem;
}

.linkClose {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 6rem;
  width: 100%;
  margin: 0.5rem 0;
  border: none;
  cursor: pointer;
}

.icon {
  color: inherit;
}

.space {
  margin-left: 1rem;
}

/* End links */
