:root {
  /* primary colors */
  --primaryDark: #13322b;
  --primarydarkLight: #4a7729;
  --primaryLighter: #cbffae;
  --primaryBG: #eaf9de;
  --greyDarkLight: #d9dbe9;
  --greyLighter: #bfc0c4;
  --greyLight: #a0a3bd;
  --greyLightest: #f6f6f6;
  --grey: #d7d8dd;
  --black: #4e4b66;
  --white: #fcfcfc;
  --text: #333;

  /* others */

  --success: #005a2d;
  --danger: #ca024f;
  --overlay: rgba(234, 249, 222, 0.55);

  /* Shadows */

  --shadowDark: 1rem 1rem 3rem rgba(74, 119, 41, 0.3);
  --shadowLight: 1rem 1rem 1rem rgba(74, 119, 41, 0.2);
}

.Toastify__toast-container {
  font-size: 1.2rem;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: var(--success);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: var(--danger);
}
