.group {
  position: relative;
}

.optionsList {
  position: absolute;
  width: 100%;
  /* margin-top: -0.2rem; */
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 1.2rem;
  list-style: none;
  overflow-y: auto;
  background-color: var(--white);
  box-shadow: var(--shadowDark);
  z-index: 999;
}

.spinner {
  position: absolute;
  width: 100%;
  height: 5rem;
  font-size: 1.4rem;
  background-color: var(--white);
  box-shadow: var(--shadowDark);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 999;
}

.spinnerBox {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* SCROLL */

.optionsList::-webkit-scrollbar {
  width: 0.6rem;
  background-color: var(--white);
  border-radius: 1.5rem;
}

.optionsList::-webkit-scrollbar-thumb {
  background-color: var(--primaryDark);
  border-radius: 1.5rem;
}

.optionsList::-webkit-scrollbar-track {
  background-color: var(--white);
}

/* END SCROLL */

.option {
  padding: 1.4rem;
  background-color: var(--white);
  color: var(--primaryDark);
  cursor: pointer;
  user-select: none;
  z-index: 999;
}

.option:hover {
  background-color: var(--primaryBG);
  color: var(--primaryDark);
}

.selected {
  background-color: #eee;
  color: var(--primaryDark);
}

.close {
  background-color: #ddd;
  width: 100%;
  padding: 0.8rem;
  z-index: 999;
  text-align: center;
  cursor: pointer;
}

.sm {
  max-height: 15.8rem;
}

.md {
  max-height: 20rem;
}

.lg {
  max-height: 28.4rem;
}
