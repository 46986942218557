.alert {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 100%;
  border-radius: 0.5rem;
}

/* Colors */

.error {
  border: 0.1rem solid var(--danger);
  color: var(--danger);
}

.success {
  border: 0.1rem solid var(--success);
  color: var(--success);
}

/*  */

.icon {
  padding-right: 0.3rem;
  width: auto;
}

.text {
  font-size: 1.2rem;
  font-weight: 500;
  user-select: none;
}

.closeBox {
  position: absolute;
  right: 0;
  height: 1.4rem;
  margin-right: 0.6rem;
}

.close {
  cursor: pointer;
}

.close:hover {
  opacity: 0.8;
}
