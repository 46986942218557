input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.3;
}

input[type='date']::-webkit-calendar-picker-indicator:hover {
  opacity: 0.9;
}

.borderDefault {
  position: relative;
  border-bottom: 0.2rem solid var(--grey);
}

.borderFocus {
  position: relative;
  border-bottom: 0.2rem solid var(--primaryDark);
}

.borderError {
  position: relative;
  border-bottom: 0.2rem solid var(--danger);
}

.input {
  background-color: var(--white);
  color: var(--greyLighter);
}

.input,
.input:active,
.input:focus {
  height: 5rem;
  width: 100%;
  padding-top: 2rem;
  border: 0;
  font-size: 1.6rem;
  outline: none;
}

.inputText {
  composes: input;
  color: var(--text);
}

.label {
  position: absolute;
  padding-top: 2.8rem;
  left: 0;
  pointer-events: none;
  font-size: 1.6rem;
  user-select: none;
}

.labelDefault {
  composes: label;
  color: var(--greyLighter);
  transition: padding 0.2s ease, font-size 0.2s ease;
}

.labelFocus {
  composes: label;
  padding-top: 0.8rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--primaryDark);
  transition: padding 0.2s ease, font-size 0.2s ease;
}

.labelDefaultDate {
  composes: labelFocus;
  color: var(--greyLighter);
  transition: none;
}

.labelErrorText {
  composes: labelFocus;
  color: var(--danger);
}

.labelErrorNoText {
  composes: labelDefault;
  color: var(--danger);
}

.error {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--danger);
  transition: all 0.1s ease;
  user-select: none;
}
