td,
th {
  text-align: left;
  padding: 1.5rem;
}

th {
  background-color: var(--primaryDark);
  color: #fcfcfc;
}

tr {
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
  background-color: var(--white);
}

.tableBox {
  height: 100%;
  background-color: var(--white);
  border-radius: 1.5rem;
  box-shadow: var(--shadowLight);
  flex-grow: 1;
  overflow: scroll;
}

.tableBox::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  background-color: var(--white);
}

.tableBox::-webkit-scrollbar-thumb {
  background-color: var(--primaryDark);
}

.tableBox::-webkit-scrollbar-track {
  background-color: var(--primaryBG);
}

.tableBox::-webkit-scrollbar,
.tableBox::-webkit-scrollbar-thumb,
.tableBox::-webkit-scrollbar-track {
  border-radius: 1.5rem;
}

.table {
  width: 100%;
  font-size: 1.6rem;
  border-collapse: collapse;
}

.table th {
  position: sticky;
  top: 0;
  height: 7rem;
}

.table td {
  height: 5rem;
}

.headerBox {
  background-color: var(--primaryDark);
}

.header {
  display: flex;
  gap: 0.5rem;
  user-select: none;
}

.center {
  text-align: center;
  justify-content: center;
}

.right {
  text-align: end;
  justify-self: flex-end;
}

.left {
  text-align: start;
  justify-content: flex-start;
}

.noRowsBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 0.5rem;
  height: 100%;
  background-color: var(--white);
  box-shadow: var(--shadowLight);
}

.noRowsHeader {
  position: absolute;
  top: 0;
  height: 7rem;
  width: 100%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 0.5rem;
  background-color: var(--primaryDark);
}

.spinnerBox {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.spinnerText {
  color: var(--primaryDark);
  font-size: 2rem;
  font-weight: 500;
}

.responseText {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: var(--primaryDark);
  font-size: 2rem;
  font-weight: 500;
}

.errorText {
  composes: responseText;
  color: var(--danger);
}
