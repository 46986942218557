* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  overflow-x: hidden;
}

abbr[title] {
  cursor: inherit;
  text-decoration: none;
  text-transform: capitalize;
}
