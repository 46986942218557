.content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
}

.topBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
