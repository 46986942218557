.searchBar {
  display: flex;
  align-items: center;
  height: 5rem;
  width: 26rem;
  padding: 1rem 2rem;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: var(--shadowDark);
}

.searchBarInput {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: var(--text);
  font-size: 1.6rem;
  font-weight: 500;
}

.icon {
  color: var(--primaryDark);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
