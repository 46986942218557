.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 12rem);
}

.code {
  display: flex;
  align-items: center;
  font-size: 22rem;
  color: var(--primaryDark);
}

.img {
  width: 16rem;
}

.messageBox {
  margin-bottom: 1.6rem;
}

.codeText {
  font-size: 3rem;
  font-weight: 500;
  color: var(--primaryDark);
}

.return {
  font-size: 2rem;
}
