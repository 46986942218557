.loader {
  border-style: solid;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.sm {
  width: 18px;
  height: 18px;
  border-width: 3px;
}

.md {
  width: 48px;
  height: 48px;
  border-width: 4px;
}

.dark {
  border-color: var(--primaryDark);
  border-bottom-color: transparent;
}

.white {
  border-color: var(--white);
  border-bottom-color: transparent;
}

.gray {
  border-color: var(--dark);
  border-bottom-color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
