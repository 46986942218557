.sidebarOpen {
  width: 26rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.sidebarOpen,
.sidebarClosed {
  color: var(--white);
  display: flex;
  justify-content: center;
  height: 100vh;
  transition: 200ms ease-in;
}

.sidebarClosed {
  width: 7.8rem;
  transition: 300ms ease-in-out;
  transition-delay: 0.1s;
}

.sidebarSection {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
}

.logoOpen {
  height: auto;
}

.logoOpen,
.logoClosed {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  user-select: none;
}

.logoClosed {
  flex-direction: column;
  gap: 2rem;
}

.imgOpen {
  height: 9rem;
}

.imgClosed {
  width: 4rem;
}

.sidebarOpen,
.sidebarClosed,
.arrowOpen,
.arrowClosed {
  background-color: var(--primaryDark);
}

.arrowOpen {
  position: absolute;
  right: -2rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 300ms;
}

.arrowClosed {
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  transition: 300ms;
  transform: rotate(180deg);
}

.arrowOpen:hover,
.arrowClosed:hover,
.iconBox:hover {
  background-color: var(--primaryBG);
  color: var(--success);
}

.linksBox {
  height: 260rem;
  overflow-y: scroll;
}

.linksBox::-webkit-scrollbar {
  display: none;
}

/* Start Sidebar Footer */

.sidebarFooter {
  height: 100%;
  margin-bottom: 1rem;
}
.sidebarFooter,
.footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.footerWrapper {
  width: 100%;
  gap: 1rem;
}

.userOpen {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  gap: 1.5rem;
  user-select: none;
}

.userClosed {
  display: none;
  user-select: none;
}

.name {
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  user-select: none;
}

.iconBox {
  display: flex;
  align-items: center;
  text-align: center;
  height: 6rem;
  width: 100%;
  margin-bottom: 1rem;
}

.icon {
  width: 100%;
  cursor: pointer;
  user-select: none;
}

/*End Sidebar Footer*/
