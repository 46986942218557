.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: var(--primaryBG);
  height: 100vh;
}

.loaderText {
  font-size: 2rem;
  color: var(--primaryDark);
  font-weight: 500;
}
