.box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 12rem);
  gap: 1.6rem;
  color: var(--primaryDark);
  user-select: none;
}

.img {
  opacity: 0.09;
}

.text {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome {
  font-size: 4.6rem;
  font-weight: 700;
  margin-bottom: 3.6rem;
  opacity: 0.6;
}

.name {
  font-size: 3rem;
  font-weight: 500;
  opacity: 0.6;
}
