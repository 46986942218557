/* Start Switch */

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  height: 100%;
}

.toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 5.5rem;
  height: 2.5rem;
  background-color: var(--white);
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  background-color: var(--grey);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 10rem;
}

.slider::before {
  position: absolute;
  content: '';
  left: 2px;
  bottom: 0.25rem;
  height: 2rem;
  width: 2rem;
  background-color: var(--black);
  transition: 0.4s;
  border-radius: 10rem;
}

input:checked + .slider {
  background-color: var(--primaryLighter);
}

input:checked + .slider:before {
  background-color: var(--primaryDark);
  transform: translateX(3.1rem);
}

.text {
  color: var(--black);
  font-size: 1.4rem;
  font-weight: 500;
  user-select: none;
}

.active {
  color: var(--primaryDark);
}

/* End Switch */
