.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 1rem;
  font-size: 1.4rem;
  user-select: none;
  box-shadow: var(--shadowLight);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  padding: 0 1rem;
}

.button:hover {
  opacity: 0.8;
}

.button:disabled {
  background-color: var(--grey);
  color: var(--black);
}

.button:hover:disabled {
  opacity: 1;
}

.dark {
  background-color: var(--primaryDark);
  color: var(--white);
}

.darklight {
  background-color: var(--primarydarkLight);
  color: var(--white);
}

.white {
  background-color: var(--white);
  color: var(--primaryDark);
}

.noShadow {
  box-shadow: none;
}

.lg {
  height: 5rem;
  min-width: 28rem;
  max-width: auto;
}

.md {
  height: 5rem;
  min-width: 15rem;
  max-width: auto;
}

.sm {
  height: 3rem;
  min-width: 4rem;
  max-width: auto;
}

.icon {
  width: auto;
  padding-right: 0.5rem;
}

.iconNoText {
  width: auto;
}

.text {
  font-weight: 900;
}
